import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import HomepageContent from './HomepageContent';
import './styles.scss';

export default function Homepage() {
  return (
    <AppLayout
      content={<HomepageContent />}
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
    />
  );
}
